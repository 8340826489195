.App {
    color: var(--text-primary);
    background-color: #2c3c5b;
    /* linear gradient background */
    /* background: linear-gradient(
        210deg,
        #000000,
        #1b042b 20%,
        #290940 50%,
        #170524 80%,
        #000000
    ); */
    transition: all 0.5s;
    text-align: center;
    font-family: "Roboto", sans-serif;
    letter-spacing: "-0.1px";
}
button {
    color: var(--text-primary);
    background-color: var(--background);
    border: 2px var(--text-primary) solid;
    float: right;
    transition: all 0.5s;
}

html {
    scroll-behavior: smooth;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
