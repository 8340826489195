/* Flip Container */
.flip-container {
    perspective: 1000px;
    position: relative;
    transition: transform 0.6s;
}

/* Flip Animation */
.flip-container:hover .flipper,
.flip-container.hover .flipper {
    transform: rotateY(180deg);
}

/* Flipper */
.flipper {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.6s;
}

/* Front and Back */
.front,
.back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.container {
    background-color: #0ac5a8;
    color: white;
    padding: "10px";
    border-radius: 5px;
}

/* Front */
.front {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Back */
.back {
    transform: rotateY(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
}
