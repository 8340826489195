@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
a {
    text-decoration: none;
    color: var(--text-primary);
}

body {
    background-color: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
}

:root {
    /* --background: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb); */
    --background: black;
    --text-primary: white;
    --text-secondary: grey;
    --accent: ;
}
